.extend_1,
body.naranjo button,
body.naranjo input,
body.naranjo optgroup,
body.naranjo select,
body.naranjo textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

body.naranjo html {
    font-family: "sans-serif";
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body.naranjo html input[type="button"] {
    -webkit-appearance: button;
    cursor: pointer;
}

body.naranjo html input[disabled] {
    cursor: default;
}

body.naranjo body {
    margin: 0;
}

body.naranjo article {
    display: block;
}

body.naranjo aside {
    display: block;
}

body.naranjo details {
    display: block;
}

body.naranjo figcaption {
    display: block;
}

body.naranjo figure {
    display: block;
    margin: 1em 40px;
}

body.naranjo footer {
    display: block;
}

body.naranjo header {
    display: block;
}

body.naranjo hgroup {
    display: block;
}

body.naranjo main {
    display: block;
}

body.naranjo menu {
    display: block;
}

body.naranjo section {
    display: block;
}

body.naranjo summary {
    display: block;
}

body.naranjo audio {
    display: inline-block;
    vertical-align: baseline;
}

body.naranjo audio:not([controls]) {
    display: none;
    height: 0;
}

body.naranjo canvas {
    display: inline-block;
    vertical-align: baseline;
}

body.naranjo progress {
    display: inline-block;
    vertical-align: baseline;
}

body.naranjo video {
    display: inline-block;
    vertical-align: baseline;
}

body.naranjo [hidden] {
    display: none;
}

body.naranjo template {
    display: none;
}

body.naranjo a {
    background-color: transparent;
}

body.naranjo a:active {
    outline: 0;
}

body.naranjo a:hover {
    outline: 0;
}

body.naranjo abbr[title] {
    border-bottom: 1px dotted;
}

body.naranjo b {
    font-weight: bold;
}

body.naranjo strong {
    font-weight: bold;
}

body.naranjo dfn {
    font-style: italic;
}

body.naranjo h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

body.naranjo mark {
    background: #ff0;
    color: #000;
}

body.naranjo small {
    font-size: 80%;
}

body.naranjo sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    bottom: -0.25em;
}

body.naranjo sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
}

body.naranjo img {
    border: 0;
}

body.naranjo hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

body.naranjo pre {
    overflow: auto;
    font-family: "monospace", "monospace";
    font-size: 1em;
}

body.naranjo code {
    font-family: "monospace", "monospace";
    font-size: 1em;
}

body.naranjo kbd {
    font-family: "monospace", "monospace";
    font-size: 1em;
}

body.naranjo samp {
    font-family: "monospace", "monospace";
    font-size: 1em;
}

body.naranjo button {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}

body.naranjo input {
    line-height: normal;
}

body.naranjo optgroup {
    font-weight: bold;
}

body.naranjo select {
    text-transform: none;
}

body.naranjo textarea {
    overflow: auto;
}

body.naranjo input[type="reset"] {
    -webkit-appearance: button;
    cursor: pointer;
}

body.naranjo input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

body.naranjo button[disabled] {
    cursor: default;
}

body.naranjo button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

body.naranjo input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

body.naranjo input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

body.naranjo input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

body.naranjo input[type="number"]::-webkit-inner-spin-button {
    height: auto;
}

body.naranjo input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

body.naranjo input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

body.naranjo input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

body.naranjo input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

body.naranjo fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

body.naranjo legend {
    border: 0;
    padding: 0;
}

body.naranjo table {
    border-collapse: collapse;
    border-spacing: 0;
}

body.naranjo td {
    padding: 0;
}

body.naranjo th {
    padding: 0;
}

body.naranjo svg:not(:root) {
    overflow: hidden;
}

body.naranjo h1,
body.naranjo h2,
body.naranjo h3,
body.naranjo h4,
body.naranjo h5 {
    font-weight: bold;
}

.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

@font-face {
    font-family: "News Gothic MT";
    src: url("./assets/fonts/NewsGothicMT.eot");
    src: url("./assets/fonts/NewsGothicMT.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/NewsGothicMT.woff") format("woff"), url("./assets/fonts/NewsGothicMT.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "News Gothic MT";
    src: url("./assets/fonts/NewsGothicMT-Bold.eot");
    src: url("./assets/fonts/NewsGothicMT-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/NewsGothicMT-Bold.woff") format("woff"), url("./assets/fonts/NewsGothicMT-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Bembo Std";
    src: url("./assets/fonts/BemboStd-Bold.eot");
    src: url("./assets/fonts/BemboStd-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/BemboStd-Bold.woff") format("woff"), url("./assets/fonts/BemboStd-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Bembo Std";
    src: url("./assets/fonts/BemboStd.eot");
    src: url("./assets/fonts/BemboStd.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/BemboStd.woff") format("woff"), url("./assets/fonts/BemboStd.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

body.naranjo {
    min-width: 320px;
    font-family: "News Gothic MT", sans-serif;
    color: #444;
    font-size: 0.9em;
}

.naranjo li a {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.naranjo a {
    color: #009cdf;
}

.modal-issuu .tingle-modal-box__content {
    padding: 0;
    overflow-y: initial;
}

.tingle-modal__close {
    font-size: 3rem !important;
}

.text-section .parag:nth-of-type(1) {
    margin-top: 0;
}

.text-section h2 {
    margin: 0 0 0.5em 0;
    font-size: 1.1em;
}

.text-section .txtblock {
    display: block;
}

.text-section span {
    line-height: 125%;
}

.subtitle {
    color: #009cdf;
    font-weight: bold;
    font-size: 1.3rem;
}

.spacer {
    display: none;
}

.wrapper {
    width: 90%;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
}

.justified,
.text-section .txtblock,
.index-article span,
.aut-single .excerpt {
    text-align: initial;
    word-break: initial;
    -webkit-hyphens: initial;
    -moz-hyphens: initial;
    -ms-hyphens: initial;
    hyphens: initial;
}

.st-header,
.st-nav,
.st-footer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5%;
}

.st-header a,
.st-nav a,
.st-footer a {
    color: inherit;
    text-decoration: none;
}

.serif {
    font-family: "Bembo Std", serif;
}

.orange {
    color: #e4610a;
}

.gray {
    color: #666;
}

.hide {
    display: none !important;
}

.button {
    cursor: pointer;
}

.st-header .redes,
.st-header .idioma,
.slogan,
.st-nav .menu,
.secondary {
    display: none;
}

.st-header {
    background: #009cdf;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    padding: 15px 5%;
}

.st-header ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-controls {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left;
    display: flex;
}

.menu-controls div {
    cursor: pointer;
    display: inline-block;
    padding: 0 12px 0 0;
}

.menu-controls img {
    height: 30px;
    vertical-align: middle;
}

.mobile-nav .menu,
.mobile-nav .sub-menu {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: none;
}

.mobile-nav .shown {
    display: block;
}

.mobile-nav .idioma li {
    display: inline-block;
    font-size: 0.8em;
    padding: 2px 8px 25px 0;
}

.mobile-nav .idioma .selected {
    font-weight: 700;
}

.mobile-nav .menu li a,
.mobile-nav .sub-menu li a {
    font-weight: 700;
}

.mobile-nav .menu li a {
    padding: 4px 0;
}

.mobile-nav .sub-menu {
    margin-left: 45px;
}

.mobile-nav .sub-menu img {
    height: 30px;
    max-width: 30px;
    margin: -4px 7px 0 0;
    vertical-align: middle;
    padding: 4px 0;
}

.st-nav {
    margin-top: 10px;
    margin-bottom: 25px;
}

.st-nav .redes {
    list-style: none;
    margin: 0;
    padding: 0;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #e4610a;
    text-align: center;
}

.st-nav .redes li {
    display: inline-block;
}

.st-nav .redes li a {
    text-decoration: none;
    color: inherit;
}

.st-nav .redes li a {
    padding: 5px 3px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.st-nav .redes li img,
.st-nav .redes li span {
    vertical-align: middle;
}

.st-nav .redes .search {
    font-size: 1em;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 2px 3px;
}

.st-nav .redes .search span {
    cursor: pointer;
    padding: 5px 3px;
}

.st-nav .redes .search input {
    display: none;
    border: none;
    background: none;
    text-align: right;
    font-size: 0.8em;
    padding: 10px 10px;
    width: 100%;
    box-sizing: border-box;
}

.st-nav .redes .search form {
    flex: 1;
}

.st-nav .redes .search-close {
    display: none;
}

.st-nav .redes .active {
    background: #fdf2e6;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.st-nav .redes .tienda {
    font-size: 0.8em;
}

.redes img {
    width: 23px;
}

.st-content {
    margin-top: 10px;
}

.index-banner {
    background: #009cdf;
    margin-bottom: 22px;
    max-width: 1366px;
    margin: 0 auto;
}

.index-banner .banner-component,
.ebooks-banner .banner-component {
    width: 100%;
}

.featured-books {
    display: flex;
    flex-wrap: wrap;
}

.featured-books .cat-libro {
    width: 50%;
}

.fbook {
    display: table-cell;
    font-size: 0.75em;
    text-align: center;
    margin-top: 10px;
    width: 50%;
}

.fbook a {
    text-decoration: none;
    color: inherit;
}

.fbook img,
.fbook strong {
    width: 100%;
}

.fbook img {
    margin-top: 10px;
    vertical-align: bottom;
}

.fbook strong {
    display: block;
}

.featured-table {
    padding: 25px 0;
    display: table;
}

.more-featured {
    width: 100%;
    text-align: center;
    padding-top: 25px;
    font-size: 0.85em;
}

.more-featured a {
    color: #e4610a;
    text-decoration: none;
    font-weight: 700;
}

.more-featured a:hover {
    color: #cc5709;
    text-decoration: underline;
}

.articles {
    margin-top: 40px;
}

.index-article {
    margin-top: 25px;
}

.index-article a {
    text-decoration: none;
    color: inherit;
}

.index-article .t-desk {
    display: none;
}

.index-article h3 {
    color: #777;
    font-size: 1.05em;
    margin-bottom: 5px;
}

.index-article img {
    width: 100%;
}

.socials {
    margin-top: 40px;
    display: none;
}

.social-fb,
.social-tw {
    margin-top: 25px;
    text-align: center;
}

.st-footer {
    margin: 35px auto 35px;
}

.copyright {
    font-size: 0.8em;
    line-height: 90%;
    text-align: center;
}

.cat-libro {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    padding: 15px;
    max-width: 273px;
}

.cat-libro a,
.cat-libro a:hover {
    text-decoration: none;
    color: inherit;
}

.cat-libro a {
    display: block;
    font-size: 0.85em;
}

.cat-libro a .title {
    font-weight: bold;
}

.cat-libro .cover-container {
    position: relative;
}

.cat-libro .cover-container:hover .ribbon {
    width: 70px;
}

.cat-libro .cover {
    width: 100%;
}

.cat-libro .ribbon {
    position: absolute;
    bottom: 5%;
    left: 20px;
    width: 63px;
    transition: width 0.4s;
}

.cont-location h3,
.cont-phonemails h3,
.cont-text h3 {
    margin: 0;
    font-size: 1em;
}

.cont-location .parag,
.cont-location .text-section,
.cont-phonemails .parag,
.cont-phonemails .text-section,
.cont-text .parag,
.cont-text .text-section {
    margin-top: 1em;
}

.cont-location .parag h2,
.cont-location .text-section h2,
.cont-phonemails .parag h2,
.cont-phonemails .text-section h2,
.cont-text .parag h2,
.cont-text .text-section h2 {
    margin-top: 2em;
}

.cont-location .parag a,
.cont-location .text-section a,
.cont-phonemails .parag a,
.cont-phonemails .text-section a,
.cont-text .parag a,
.cont-text .text-section a {
    font-size: inherit;
    font-weight: inherit;
}

.breakall {
    word-break: break-all;
}

div.breakall {
    display: inline-block;
}

.distrib strong:before {
    content: "";
    display: block;
    margin-top: 0.5em;
}

.distrib strong:not(:nth-of-type(1)) {
    margin-top: 1em;
}

.distrib .bookstorename {
    color: #e4610a;
    font-style: normal;
}

.cont .distrib a {
    color: #444;
}

.cont-phonemails {
    margin-top: 2em;
}

.cont-phonemails a {
    text-decoration: none;
    color: inherit;
}

.cont-phonemails a {
    color: #e4610a;
}

.cont-text a {
    text-decoration: none;
    color: inherit;
}

.cont-text a {
    color: #e4610a;
    font-size: 0.8em;
    font-weight: 700;
}

#map {
    width: 100%;
    height: 300px;
    margin: 15px auto;
}

.nosot-picture {
    margin-top: 25px;
}

.nosot-picture img {
    width: 100%;
}

.nosot-text {
    margin-top: 25px;
}

.nosot-text .quote {
    color: #e4610a;
    font-style: italic;
    font-size: 1.2em;
}

.nosot-text .parag {
    margin-top: 1em;
}

.nosot-text .parag:nth-of-type(1) {
    margin-top: 0;
}

.cat-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cat-list img {
    width: 100%;
}

.cat-filters input[type="radio"] {
    display: none;
}

.cat-filters {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    padding: 0 10%;
}

.cat-filters>div ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
}

.cat-filters>div ul li {
    padding: 5px 0;
}

.cat-filters>div ul li label {
    display: inline-block;
    width: 100%;
}

.cat-filters>label,
.cat-filters>div label,
.cat-filters>div span {
    cursor: pointer;
}

.cat-filters>label,
.cat-filters>div {
    width: 33.3%;
    text-align: center;
}

.cat-filters>#filterC {
    width: 100%;
}

.cat-filters label,
.cat-filters .dropdown-label {
    color: #909090;
}

.cat-filters .switch,
.cat-filters span {
    color: #e4610a;
}

.cat-filters span {
    display: block;
}

.cat-filters .active {
    color: #444;
    font-weight: bold;
}

.switch {
    display: inline-block;
}

.switch input {
    display: none;
}

.switch input:checked+.switch_label {
    font-weight: bold;
}

.switch .switch_label {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.storeembed {
    margin-top: 1em;
    width: 100%;
    overflow: hidden;
}

.ca-slider,
.pl-slider {
    width: 100%;
}

.ca-slider .royalSlider,
.pl-slider .royalSlider {
    width: 90%;
    margin: 0 auto;
}

.ca-slider {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 5%;
}

.ca-slider .royalSlider .rsThumb {
    text-align: center;
}

.ca-slider .royalSlider .rsThumb img {
    width: auto;
}

.disabled {
    cursor: default;
}

#libro-content {
    display: none;
}

.st-content.libro {
    margin-top: 0;
}

.lm-portada {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 30px;
    text-align: left;
    font-size: 0.8em;
}

.lm-portada a {
    text-decoration: none;
    color: inherit;
}

.lm-portada img {
    width: 100%;
}

.lm-portada a {
    color: #909090;
}

.lm-galeria {
    margin-bottom: 60px;
}

.lm-galeria .royalSlider {
    width: 100%;
}

.lm-galeria .royalSlider .rsBullet {
    width: 10px;
    height: 10px;
}

.lm-galeria .royalSlider .rsBullet.rsNavSelected span {
    background-color: #e4610a;
}

.lm-galeria .royalSlider .rsBullet span {
    width: 10px;
    height: 10px;
    border: 1px solid #e4610a;
    background: none;
}

.libro-detalles {
    margin-bottom: 30px;
}

.libro-detalles ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ld-datos {
    margin-bottom: 10px;
}

.ld-tabs {
    margin-bottom: 10px;
}

.ld-tabs>ul {
    margin-bottom: 10px;
}

.ld-tabs>ul li {
    float: left;
    width: 50%;
    font-size: 0.85em;
}

.ld-tabs div {
    color: #666;
    font-size: 0.85em;
    line-height: 1.4em;
}

.ld-temas {
    font-size: 0.85em;
}

.ld-temas ul {
    margin-top: 5px;
    color: #666;
}

.ld-coleccion img {
    width: 150px;
    margin-bottom: 5px;
}

.ld-coleccion div {
    font-size: 1.2em;
}

.libro-contenido {
    margin-bottom: 40px;
}

.libro-contenido h2 {
    color: #e4610a;
    margin-top: 0;
    font-weight: bold;
    font-size: 1.3em;
}

.lc-autores {
    font-size: 1.2em;
    margin-bottom: 1em;
}

.lc-autores a {
    text-decoration: none;
    color: inherit;
}

.libro-misc {
    margin-bottom: 20px;
}

.lmi-bloque {
    float: left;
}

.lmi-bloque>div {
    width: 100%;
    float: left;
}

.lmi-bloque-premios {
    margin-bottom: 15px;
}

.lmi-bloque-premios ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.lmi-bloque-premios ul li {
    float: left;
    margin-right: 1em;
}

.lmi-bloque-premios ul li img {
    width: 50px;
}

.lmi-bloque-premios ul li:last-of-type {
    margin-right: 0;
}

.lmi-bloque-links {
    margin-bottom: 15px;
}

.lmi-bloque-links a {
    text-decoration: none;
    color: inherit;
}

.lmi-bloque-links a {
    display: block;
    font-weight: 700;
    color: #e4610a;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.lmi-bloque-links a img {
    width: 40px;
    margin-right: 5px;
}

.lmi-bloque-tiendas {
    margin-bottom: 15px;
}

.lmi-bloque-tiendas a {
    text-decoration: none;
    color: inherit;
}

.lmi-bloque-tiendas img {
    vertical-align: middle;
    margin-right: 5px;
}

.lmi-bloque-tiendas span {
    font-size: 1.2em;
    font-weight: 700;
}

.lmi-bloque-tiendas .price_paperback,
.lmi-bloque-tiendas .price_hardcover {
    display: none;
}

.lmi-bloque-ebooks {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}

.lmi-bloque-ebooks>div,
.lmi-bloque-ebooks>img {
    float: left;
}

.lmi-bloque-ebooks>img {
    vertical-align: middle;
    margin-right: 5px;
    width: 33px;
}

.lmi-bloque-ebooks>div {
    display: flex;
}

.lmi-bloque-ebooks div a {
    display: block;
}

.lmi-bloque-ebooks div a:nth-of-type(1) img {
    margin-right: 15px;
}

.lmi-bloque-ebooks div a img {
    display: block;
}

.libro-relacionados {
    margin-top: 40px;
}

.libro-relacionados h2 {
    color: #e4610a;
    margin: 0;
    font-size: 1.3em;
}

.libro-relacionados strong {
    font-size: 0.85em;
}

.libro-relacionados .cat-libro {
    padding-top: 0;
}

.filterLinks {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.filterLinks span,
.filterLinks a {
    text-decoration: none;
    color: #009cdf;
    padding: 20px;
}

.aut-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aut-single {
    width: 100%;
    margin-bottom: 30px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.aut-single a {
    text-decoration: none;
    color: inherit;
}

.aut-single .header,
.aut-single span {
    color: #009cdf;
}

.aut-single .header {
    font-size: 1.1em;
}

.aut-single a {
    color: #444;
    display: block;
    display: flex;
}

.aut-single span {
    font-size: 0.85em;
}

.aut-single .info,
.aut-single .photo {
    float: left;
}

.aut-single .info {
    margin-bottom: 5px;
}

.aut-single .photo img {
    width: 100px;
    margin-right: 15px;
}

.aut-index {
    width: 50%;
    text-align: center;
    margin: 0 auto 0.5rem auto;
}

.aut-index label {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    padding: 0 5px 1rem 5px;
    font-size: 1.2em;
    cursor: pointer;
}

.aut-index label input {
    display: none;
}

.author-info img {
    margin: 0 auto;
    display: block;
    width: 70%;
    max-width: 200px;
}

.author-info h2 {
    color: #009cdf;
    font-size: 1.5em;
    font-weight: bold;
}

.author-books {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.author-books .cat-libro {
    padding-right: 0;
    padding-left: 0;
    max-width: 150px;
}

.single-video-container {
    width: 100%;
    cursor: pointer;
    margin-bottom: 30px;
}

.single-video-container .video-image {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    background-size: 100%;
}

.single-video-container .video-data {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.modal-videos .tingle-modal-box__content {
    padding: 0;
    overflow-y: initial;
}

.modal-videos .videoplayer {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.modal-videos iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

body.naranjo .page-ebooks {
    display: flex;
    flex-direction: column;
}

body.naranjo .page-ebooks .sidebar {
    width: 100%;
}

body.naranjo .page-ebooks .sidebar .banner-component {
    width: 100%;
}

body.naranjo .page-ebooks .main-text {
    display: flex;
    flex-direction: column-reverse;
}

body.naranjo .page-ebooks .platforms {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

body.naranjo .page-ebooks .platforms a {
    display: block;
    width: 40%;
    text-align: center;
}

body.naranjo .page-ebooks .platforms a img {
    height: 50px;
}

body.naranjo .page-rights {
    display: flex;
    flex-direction: column-reverse;
}

body.naranjo .page-rights .sidebar {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}

body.naranjo .page-rights .sidebar a {
    text-decoration: none;
    color: inherit;
}

body.naranjo .page-rights .sidebar a {
    display: block;
    width: 100%;
    margin-bottom: 15px;
}

body.naranjo .page-rights .sidebar a img {
    width: 100%;
}

body.naranjo .page-rights .main-text {
    margin-bottom: 25px;
}

.pdfviewer {
    width: 100%;
    position: relative;
    padding-bottom: 350px;
    height: 0;
}

.pdfviewer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.search form {
    display: inline;
}

#search-term {
    font-weight: bolder;
}

#search-results {
    display: flex;
    flex-direction: column;
}

.single-result {
    display: block;
    text-decoration: none;
}

.single-result div {
    padding: 15px 0;
}

.single-result div:hover {
    background-color: rgba(0, 156, 223, 0.08);
}

.search-title {
    font-size: 1.2em;
    margin-bottom: 15px;
}

.blog-main {
    display: flex;
    flex-direction: column;
}

.blog-main .blog-entry {
    flex-direction: column;
    margin-bottom: 25px;
}

.blog-main .blog-entry .entry-continue {
    font-size: 0.95em;
}

.blog-main .blog-entry .entry-title {
    text-decoration: none;
    color: #444;
}

.blog-main .blog-entry h3 {
    font-size: 1.3em;
    margin: 0 0 10px 0;
}

.blog-main .entry-picture {
    width: 100%;
    position: relative;
    padding-bottom: 36.25%;
    height: 0;
    margin-bottom: 10px;
}

.blog-main .entry-picture a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.single-post {
    display: flex;
    flex-direction: column;
}

.single-post .suggested {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.single-post .suggested .blog-entry {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.single-post .suggested .blog-entry h3 {
    font-size: 1.1em;
    margin: 0 0 10px 0;
}

.single-post .suggested .entry-title {
    text-decoration: none;
    color: #444;
}

.single-post .suggested .entry-picture {
    width: 100%;
    position: relative;
    padding-bottom: 36.25%;
    height: 0;
    margin-bottom: 5px;
}

.single-post .suggested .entry-picture a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.single-post .post-body .featured-image {
    width: 100%;
    position: relative;
    padding-bottom: 36.25%;
    height: 0;
    margin-bottom: 25px;
}

.single-post .post-body .post-title h1 {
    margin: 0 0 5px 0;
    font-size: 1.4em;
}

.single-post .post-body .post-title time {
    margin-bottom: 20px;
    font-size: 0.9em;
    color: #444;
}

.single-post .post-content p {
    text-align: justify;
    word-break: normal;
    hyphens: initial;
}

@media screen and (min-width: 480px) {
    .st-nav .menu>div:nth-of-type(2) {
        margin-top: 8px;
    }
    .st-nav .redes .tienda {
        font-weight: 700;
    }
    .featured-books .cat-libro {
        width: 33.3%;
    }
    .fbook {
        width: 33.3%;
    }
    .cont.left-col,
    .cont.right-col {
        width: 45%;
    }
    .cont.left-col {
        float: left;
    }
    .cont.right-col {
        float: right;
        margin-left: 5%;
    }
    .cont.right-col .cont-text {
        margin-top: 0;
    }
    .asd {
        padding: 0;
    }
    #videos-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #videos-section .single-video-container {
        width: 45%;
    }
    .single-post .suggested .blog-entry {
        flex-direction: row;
        align-items: center;
    }
    .single-post .suggested .blog-entry .entry-picture {
        padding-bottom: 20%;
        width: 50%;
        margin-right: 15px;
    }
    .single-post .suggested .blog-entry .entry-excerpt {
        width: 50%;
    }
}

@media screen and (min-width: 640px) {
    .spacer {
        display: block;
    }
    .justified,
    .text-section .txtblock,
    .index-article span,
    .aut-single .excerpt {
        text-align: justify;
        word-break: normal;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
    .menu-controls,
    .st-nav .redes {
        display: none;
    }
    .st-header .redes,
    .st-header .idioma {
        display: block;
        float: right;
    }
    .st-header .redes {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .st-header .redes li {
        display: inline-block;
        padding-left: 5px;
    }
    .st-header .redes li img {
        width: 25px;
    }
    .st-header .idioma {
        font-size: 0.8em;
        margin: 25px 0 0 0;
    }
    .st-header .idioma .selected {
        font-weight: 700;
    }
    .st-nav .menu {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;
        text-align: center;
    }
    .st-nav .menu li {
        color: #e4610a;
        display: inline-block;
        font-weight: 700;
        font-size: 1em;
        margin: 0 10px;
        vertical-align: middle;
    }
    .st-nav .menu li a {
        text-decoration: none;
        color: inherit;
    }
    .st-nav .menu li:hover:not(.search) {
        color: #009cdf;
    }
    .st-nav .menu .tienda img {
        width: 33px;
        vertical-align: middle;
        margin-right: 5px;
    }
    .st-nav .menu .tienda br {
        display: none;
    }
    .st-nav .menu .search {
        background: #fdf2e6;
        padding: 1px 5px;
    }
    .st-nav .menu .search input {
        box-sizing: border-box;
        padding-left: 10px;
        background: none;
        border: none;
        font-weight: 300;
        color: #e4610a;
        width: 120px;
        font-size: 0.95em;
    }
    .copyright br {
        display: none;
    }
    .index-article {
        display: table-cell;
        width: 33%;
    }
    .index-article:nth-of-type(1) {
        padding-right: 0.25%;
    }
    .index-article:nth-of-type(2) {
        padding: 0 0.25%;
    }
    .index-article:nth-of-type(3) {
        padding-left: 0.25%;
    }
    .index-article .t-mob {
        display: none;
    }
    .index-article .t-desk {
        display: block;
    }
    .index-article h3 {
        margin-top: 5px;
    }
    .socials {
        display: block;
    }
    .social-fb,
    .social-tw {
        max-width: 500px;
        max-height: 800px;
        height: 800px;
        width: 50%;
        box-sizing: border-box;
    }
    .social-fb:nth-of-type(1),
    .social-tw:nth-of-type(1) {
        padding-right: 2.5%;
        float: left;
    }
    .social-fb:nth-of-type(2),
    .social-tw:nth-of-type(2) {
        padding-left: 2.5%;
        float: right;
    }
    
    #map {
        height: 450px;
    }
    .nosot-picture,
    .nosot-text {
        float: left;
        width: 50%;
        box-sizing: border-box;
    }
    .nosot-picture {
        padding-right: 2.5%;
    }
    .nosot-text {
        padding-left: 2.5%;
    }
    .lm-portada,
    .lm-galeria,
    .libro-detalles,
    .libro-contenido {
        float: left;
    }
    .libro-misc {
        float: right;
    }
    .libro-media {
        margin-bottom: 25px;
    }
    .lm-portada,
    .libro-detalles,
    .lmi-bloque:nth-of-type(1) {
        width: 25%;
    }
    .lm-galeria,
    .libro-contenido,
    .lmi-bloque:nth-of-type(2) {
        width: 70%;
    }
    .lm-galeria {
        margin: 0 0 0 5%;
    }
    .libro-detalles {
        margin: 0 0 25px 0;
    }
    .libro-contenido {
        margin: 0 0 25px 5%;
    }
    .libro-misc {
        width: 100%;
        margin: 0 0 25px 0;
    }
    .lmi-bloque:nth-of-type(2) {
        margin-left: 5%;
    }
    .aut-single {
        width: 45%;
    }
    .author-info {
        display: flex;
    }
    .author-info .foto {
        width: 30%;
    }
    .author-info .foto img {
        width: 100%;
    }
    .author-info .data {
        width: 70%;
        padding-left: 20px;
    }
    .author-info .data h2 {
        margin-top: 0;
        padding-top: 0;
    }
    .author-books {
        margin-left: 30%;
    }
    #videos-section .single-video-container {
        width: 30%;
    }
    body.naranjo .page-ebooks {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    body.naranjo .page-ebooks .sidebar {
        width: 35%;
    }
    body.naranjo .page-ebooks .main-text {
        width: 60%;
        flex-direction: column;
    }
    body.naranjo .page-ebooks .main-text .platforms {
        margin-top: 30px;
    }
    body.naranjo .page-ebooks .main-text .platforms a img {
        height: 70px;
    }
    body.naranjo .page-rights {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    body.naranjo .page-rights .sidebar {
        width: 25%;
        flex-direction: column;
    }
    body.naranjo .page-rights .main-text {
        width: 70%;
    }
    .pdfviewer {
        padding-bottom: 250px;
    }
    .blog-main .blog-entry {
        display: flex;
        flex-direction: row-reverse;
    }
    .blog-main .entry-picture {
        width: 30%;
        padding-bottom: 25%;
        margin: 0 0 0 10px;
    }
    .blog-main .entry-excerpt {
        width: 70%;
    }
}

@media screen and (min-width: 768px) {
    .slogan {
        display: block;
        float: left;
        margin: 30px 0 0 35px;
    }
    .slogan img {
        width: 270px;
    }
    .st-nav .menu>div {
        display: inline-block;
    }
    .featured-books .cat-libro {
        width: 25%;
    }
    .fbook {
        width: 25%;
    }
    .secondary {
        display: block;
        box-sizing: border-box;
        padding: 0;
        margin-top: 40px;
    }
    .secondary img {
        width: 100%;
        vertical-align: middle;
    }
    .secondary ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
    }
    .secondary ul li {
        box-sizing: border-box;
        padding: 5px;
        float: left;
        width: 16%;
        max-width: 91.3px;
        vertical-align: middle;
        text-align: center;
        color: #009cdf;
        font-weight: 700;
    }
    .secondary ul li a {
        text-decoration: none;
        color: inherit;
    }
    .index-article {
        width: 30%;
    }
    .index-article:nth-of-type(1) {
        padding-right: 2.5%;
    }
    .index-article:nth-of-type(2) {
        padding: 0 1.25%;
    }
    .index-article:nth-of-type(3) {
        padding-left: 2.5%;
    }
    .st-footer {
        padding: 0;
    }
    .copyright {
        padding-top: 15px;
    }
    .libro-contenido {
        margin: 0 5% 25px 5%;
    }
    .libro-contenido {
        width: 40%;
    }
    .libro-misc {
        float: right;
        width: 25%;
    }
    .libro-misc .lmi-bloque {
        width: 100%;
    }
    .lmi-bloque:nth-of-type(2) {
        margin-left: 0;
    }
    .libro-relacionados {
        width: 70%;
        margin-right: 0;
        margin-left: auto;
    }
    .cat-filters {
        padding: 15px 0;
    }
    .cat-filters>label,
    .cat-filters>div {
        margin-bottom: 15px;
    }
    .cat-filters,
    .cat-list {
        float: left;
    }
    .cat-filters {
        flex-direction: column;
        width: 20%;
    }
    .cat-filters>div ul {
        display: inline;
    }
    .cat-filters>div,
    .cat-filters>label {
        width: 100%;
        text-align: left;
    }
    #filterA {
        margin-bottom: 30px;
    }
    #filterC ul span {
        margin-top: 15px;
    }
    .cat-list {
        width: 80%;
    }
    .dropdown-label {
        display: none;
    }
    #videos-section .single-video-container {
        width: 22.5%;
    }
    .pdfviewer {
        padding-bottom: 350px;
    }
    .blog-main .entry-picture {
        padding-bottom: 18%;
    }
    .single-post .suggested {
        flex-direction: row;
        justify-content: space-between;
    }
    .single-post .suggested .blog-entry {
        width: 45%;
        flex-direction: column;
    }
    .single-post .suggested .blog-entry .entry-picture {
        padding-bottom: 36%;
        width: 100%;
        margin-right: 0;
    }
    .single-post .suggested .blog-entry .entry-excerpt {
        width: 100%;
    }
}

@media screen and (min-width: 1000px) {
    .st-header .redes li {
        display: inline-block;
        padding-left: 5px;
    }
    .st-header .redes li img {
        width: 40px;
    }
    .st-nav .menu .tienda img {
        width: 40px;
    }
    .st-nav .menu .tienda br {
        display: inline;
    }
    .blog-main .entry-picture {
        padding-bottom: 13%;
    }
    .featured-image {
        padding-bottom: 26.25%;
    }
    .single-post {
        flex-direction: row;
        justify-content: space-between;
    }
    .single-post .suggested {
        width: 22.5%;
        flex-direction: column;
        justify-content: unset;
        margin: 0;
    }
    .single-post .suggested .blog-entry {
        width: 100%;
    }
    .single-post .post-body {
        width: 67.5%;
    }
}